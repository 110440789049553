<template>
  <base-card no-header class="width-100">
    <template #body>
      <el-row>
        <el-col :span="8">
          <h3>{{ resource.name }}</h3>
          <span v-if="resource" class="body-1">
            {{ $t(Object.keys(ResourceType)[resource.resourceType]) }}
          </span>
        </el-col>
        <el-col :span="12">
          <div
            v-if="
              includesResourceType(
                [
                  ResourceType.AiServices,
                  ResourceType.ContainerRegistry,
                  ResourceType.DataFactory,
                  ResourceType.DataLakeStorage,
                  ResourceType.EventGridTopic,
                  ResourceType.KeyVault,
                  ResourceType.MachineLearningWorkspace,
                  ResourceType.SqlServer,
                  ResourceType.StorageAccount,
                  ResourceType.SynapseAnalytics
                ],
                resource.resourceType
              )
            "
          >
            <h4>{{ $t('privateEndpoints') }}</h4>
            <span v-if="!privateEndpointInfo.length" class="body-1 d-block">
              {{ $t('none') }}
            </span>
            <span
              v-for="privateEndpoint in privateEndpointInfo"
              :key="privateEndpoint.ip"
              class="body-1 d-block"
            >
              {{ privateEndpoint.ip + ' ' + privateEndpoint.fqdn }}
            </span>
          </div>
          <div
            v-if="
              includesResourceType(
                [ResourceType.SqlDatabase, ResourceType.SynapseDataWarehouse],
                resource.resourceType
              )
            "
          >
            <h4>{{ $t('sqlServerName') }}</h4>
            <span class="body-1 d-block">
              {{ sqlServerInfo || $t('none') }}
            </span>
          </div>
        </el-col>
        <el-col :span="4" class="d-flex">
          <div
            v-if="
              includesResourceType(
                [
                  ResourceType.AnalysisServices,
                  ResourceType.DataFactory,
                  ResourceType.GitRepository,
                  ResourceType.PowerBIWorkspace
                ],
                resource.resourceType
              )
            "
            class="ml-auto"
          >
            <el-button type="primary" @click="redirect">
              <h4>{{ $t('open') }}</h4>
            </el-button>
          </div>
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>

<script lang="ts">
import { ElRow, ElCol, ElButton } from 'element-plus';
import { onMounted, ref } from 'vue';
import BaseCard from '@/components/panels/BaseCard.vue';
import { PrivateEndpoint } from '@/services/web-api/dto/privateEndpoint';
import { TenantModel } from '@etp/etp-infra-client';
import { ResourceType, ResourceModel } from '@etp/etp-bff-client/axios';
import config from '@/config/config';

export default {
  name: 'TenantConfigurationTable',
  components: { ElRow, ElCol, BaseCard, ElButton },
  props: {
    resource: { type: Object as () => ResourceModel, required: true },
    tenant: {
      type: Object as () => TenantModel,
      required: true
    }
  },
  setup(props) {
    const privateEndpointInfo = ref(new Array<PrivateEndpoint>());
    const sqlServerInfo = ref('');
    const resourceConfiguration = ref({});

    const processResourceConfiguration = () => {
      if (!props.resource.configuration) return;
      resourceConfiguration.value = JSON.parse(props.resource.configuration);
    };

    const setPrivateEndpointInfo = () => {
      let privateEndpoints = resourceConfiguration.value['privateEndpoints'];
      if (!privateEndpoints || !privateEndpoints.length) return;
      privateEndpoints.forEach((el: any) => {
        privateEndpointInfo.value.push({
          ip: el['IP'],
          fqdn: el['FQDN']
        });
      });
    };

    const setSqlServerInfo = () => {
      sqlServerInfo.value = resourceConfiguration.value['sqlServerName'];
    };

    const redirect = () => {
      if (!props.tenant.cloudConfiguration) {
        return;
      }
      let cloudConfiguration = ref({});
      cloudConfiguration.value = JSON.parse(props.tenant.cloudConfiguration);

      let subscriptionId = cloudConfiguration.value['SubscriptionId'];

      switch (props.resource.resourceType) {
        case ResourceType.AnalysisServices: {
          let resourceGroupName = cloudConfiguration.value['ResourceGroupName'];
          window.open(
            `https://portal.azure.com/#@deloitte.onmicrosoft.com/resource/subscriptions/${subscriptionId}/resourceGroups/${resourceGroupName}/providers/Microsoft.AnalysisServices/servers/${props.resource.name}/serverDatabase`,
            '_blank'
          );
          break;
        }
        case ResourceType.DataFactory:
          window.open(
            `https://adf.azure.com/en/authoring?factory=%2Fsubscriptions%2F${subscriptionId}%2FresourceGroups%2F${props.resource.name}`,
            '_blank'
          );
          break;
        case ResourceType.GitRepository:
          window.open(
            `https://dev.azure.com/dttnl-tax-ita/${config.nodeModelGitProjectName}/_git/${props.resource.name}`,
            '_blank'
          );
          break;
        case ResourceType.PowerBIWorkspace: {
          let workspaceId = resourceConfiguration.value['workspaceId'];
          if (!workspaceId) return;
          window.open(
            `https://app.powerbi.com/groups/${workspaceId}/list`,
            '_blank'
          );
          break;
        }
      }
    };

    const includesResourceType = (
      resourceTypes: ResourceType[],
      resourceTypeToCheck: ResourceType
    ) => {
      return resourceTypes.includes(resourceTypeToCheck);
    };

    onMounted(() => {
      processResourceConfiguration();
      setPrivateEndpointInfo();
      setSqlServerInfo();
    });

    return {
      includesResourceType,
      ResourceType,
      privateEndpointInfo,
      sqlServerInfo,
      redirect
    };
  }
};
</script>
