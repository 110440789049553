import axios from 'axios';
import config from '@/config/config';
import msal from '@/services/msal/msal';
import { MsalGetTokenOptions } from './msal/types';
import { AccessToken } from '@azure/core-auth';
import ErrorNotification from '@/components/misc/ErrorNotification';
import {
  Configuration as AuthorizationConfiguration,
  AuthorizationApiFactory
} from '@etp/etp-authorization-client/axios';
import {
  Configuration as NodeModelConfiguration,
  NodeModelApiFactory
} from '@etp/etp-nodemodelgit-client/axios';
import {
  Configuration as TaskSchedulerConfiguration,
  TaskSchedulerApiFactory
} from '@etp/etp-taskscheduler-client/axios';
import {
  Configuration as ReportingConfiguration,
  ReportConfigurationApiFactory
} from '@etp/etp-reporting-client/axios';
import {
  Configuration as InfraConfiguration,
  CostsApiFactory as InfraCostsApiFactory,
  DeploymentApiFactory as InfraDeploymentApiFactory,
  DeploymentTaskApiFactory as InfraDeploymentTaskApiFactory,
  ResourceApiFactory as InfraResourceApiFactory,
  TenantApiFactory as InfraTenantApiFactory
} from '@etp/etp-infra-client';
import i18n from '@/i18n';
import {
  Configuration as ProfileConfiguration,
  ProfileApiFactory
} from '@etp/etp-profile-client/axios';
import {
  Configuration as PipelinesConfiguration,
  PipelinesApiFactory
} from '@etp/etp-pipelines-client/axios';
import {
  Configuration as StorageConfiguration,
  ConfigurationApiFactory as StorageConfigurationApiFactory,
  FileUploadApiFactory as StorageFileUploadApiFactory,
  ObjectsApiFactory as StorageObjectsApiFactory
} from '@etp/etp-storage-client/axios';
import {
  Configuration as EngagementsConfiguration,
  EngagementApiFactory
} from '@etp/etp-engagements-client/axios';
import {
  Configuration as DashboardingConfiguration,
  DashboardingApiFactory
} from '@etp/etp-dashboarding-client/axios';
import {
  Configuration as EInvoicingConfiguration,
  EInvoicingApiFactory
} from '@etp/etp-einvoicing-client/axios';
import {
  Configuration as RbacConfiguration,
  RBACApiFactory
} from '@etp/etp-rbac-client';
import {
  Configuration as CostSavingCofiguration,
  AutoPauseExclusionsApiFactory
} from '@etp/etp-costsaving-client/axios';
import {
  Configuration as ConnectorsConfiguration,
  ConfigurationApiFactory,
  ConnectorsApiFactory
} from '@etp/etp-connectors-client';
import {
  Configuration as GainBffConfiguration,
  BFFApiFactory
} from '@etp/etp-gain-bff-client/axios';
import {
  Configuration as BFFConfiguration,
  BFFApiFactory as APIBFFApiFactory
} from '@etp/etp-bff-client/axios';

const getTokenAsync = async resource => {
  return new Promise<AccessToken>((resolve, reject) => {
    msal
      .getToken({
        tenantId: resource.tenantId,
        clientId: resource.clientId,
        scopes: resource.scopes
      } as MsalGetTokenOptions)
      .then(token => {
        return resolve({
          token: token?.token,
          expiresOnTimestamp: token?.expiresOnTimestamp
        } as AccessToken);
      })
      .catch(() => {
        return reject('Failed to get the token');
      });
  });
};

export function createAuthorisationClient() {
  const authorisationApi = 'authorisationEndpoint';
  return AuthorizationApiFactory(
    new AuthorizationConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[authorisationApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[authorisationApi]['uri']
  );
}
export function createNodeModelClient() {
  const nodeModelApi = 'nodeModelEndpoint';
  return NodeModelApiFactory(
    new NodeModelConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[nodeModelApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[nodeModelApi]['uri']
  );
}
export function createTaskSchedulerApiClient() {
  const taskApi = 'taskSchedulerEndpoint';
  return TaskSchedulerApiFactory(
    new TaskSchedulerConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[taskApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[taskApi]['uri']
  );
}

export function createReportingConfigurationApiClient() {
  const reportingConfigurationApi = 'reportingEndpoint';
  return ReportConfigurationApiFactory(
    new ReportingConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(
          config.api[reportingConfigurationApi]
        );
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[reportingConfigurationApi]['uri']
  );
}

export function createInfraCostsClient() {
  const infraApi = 'infraEndpoint';
  return InfraCostsApiFactory(
    new InfraConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[infraApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[infraApi]['uri']
  );
}

export function createInfraDeploymentClient() {
  const infraApi = 'infraEndpoint';
  return InfraDeploymentApiFactory(
    new InfraConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[infraApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[infraApi]['uri']
  );
}

export function createInfraDeploymentTaskClient() {
  const infraApi = 'infraEndpoint';
  return InfraDeploymentTaskApiFactory(
    new InfraConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[infraApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[infraApi]['uri']
  );
}

export function createInfraResourceClient() {
  const infraApi = 'infraEndpoint';
  return InfraResourceApiFactory(
    new InfraConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[infraApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[infraApi]['uri']
  );
}

export function createInfraTenantClient() {
  const infraApi = 'infraEndpoint';
  return InfraTenantApiFactory(
    new InfraConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[infraApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[infraApi]['uri']
  );
}

export function createProfileApiClient() {
  const profileApi = 'profileEndpoint';
  return ProfileApiFactory(
    new ProfileConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[profileApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[profileApi]['uri']
  );
}

export function createPipelinesApiClient() {
  const pipelinesApi = 'pipelinesEndpoint';
  return PipelinesApiFactory(
    new PipelinesConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[pipelinesApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[pipelinesApi]['uri']
  );
}

export function createStorageConfigurationApiClient() {
  const storageApi = 'storageEndpoint';
  return StorageConfigurationApiFactory(
    new StorageConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[storageApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[storageApi]['uri']
  );
}

export function createStorageFileUploadApiClient() {
  const storageApi = 'storageEndpoint';
  return StorageFileUploadApiFactory(
    new StorageConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[storageApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[storageApi]['uri']
  );
}

export function createStorageObjectsApiClient() {
  const storageApi = 'storageEndpoint';
  return StorageObjectsApiFactory(
    new StorageConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[storageApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[storageApi]['uri']
  );
}

export function createEngagementsApiClient() {
  const engagementsApi = 'engagementsEndpoint';
  return EngagementApiFactory(
    new EngagementsConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[engagementsApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[engagementsApi]['uri']
  );
}

export function createDashboardingApiClient() {
  const dashboardingApi = 'dashboardingEndpoint';
  return DashboardingApiFactory(
    new DashboardingConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[dashboardingApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[dashboardingApi]['uri']
  );
}

export function createEInvoicingApiClient() {
  const einvoicingApi = 'einvoicingEndpoint';
  return EInvoicingApiFactory(
    new EInvoicingConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[einvoicingApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[einvoicingApi]['uri']
  );
}

export function createRbacApiClient() {
  const rbacApi = 'rbacEndpoint';
  return RBACApiFactory(
    new RbacConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[rbacApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[rbacApi]['uri']
  );
}

export function createCostManagementClient() {
  const costManagementApi = 'costManagementEndpoint';
  return AutoPauseExclusionsApiFactory(
    new CostSavingCofiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[costManagementApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[costManagementApi]['uri']
  );
}

export function createConnectorsClient() {
  const connectorsApi = 'connectorsEndpoint';
  return ConnectorsApiFactory(
    new ConnectorsConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[connectorsApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[connectorsApi]['uri']
  );
}

export function createConnectorsConfigurationClient() {
  const connectorsApi = 'connectorsEndpoint';
  return ConfigurationApiFactory(
    new ConnectorsConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[connectorsApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[connectorsApi]['uri']
  );
}

export function createGainBffClient() {
  const gainBffApi = 'gainBffEndpoint';
  return BFFApiFactory(
    new GainBffConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[gainBffApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[gainBffApi]['uri']
  );
}

export function createApiBffClient() {
  const bffApi = 'bffEndpoint';
  return APIBFFApiFactory(
    new BFFConfiguration({
      accessToken: async () => {
        const { token } = await getTokenAsync(config.api[bffApi]);
        return token;
      },
      baseOptions: {
        headers: {}
      }
    }),
    config.api[bffApi]['uri']
  );
}

export default (env: string) => {
  const client = axios.create({
    baseURL: config.api[env]['uri'],
    timeout: 30000
  });

  client.interceptors.request.use(
    async reqConfig => {
      let token = await getTokenAsync(config.api[env]);
      reqConfig.headers.authorization = `Bearer ${token.token}`;
      return Promise.resolve(reqConfig);
    },
    err => Promise.reject(err)
  );
  client.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response) {
        if (error.response.status == 500) {
          ErrorNotification({
            title: i18n.global.t('error'),
            message: error.message
          });
        } else {
          console.error(error.response.data);
        }
        return error.response;
      } else if (error.request) {
        return error.request;
      }
      return Promise.reject(error);
    }
  );
  return client;
};
