<template>
  <el-row class="d-flex">
    <span class="body-1 cursor-pointer" @click="openVersionModal">
      {{ $t('currentVersion') + ': ' + gainImportConfiguration.version }}
    </span>
    <el-button
      class="ml-auto"
      type="primary"
      :disabled="!userCanWriteEngagementGainImportConfiguration || isSignedOff"
      @click="addEntry"
    >
      <h4>{{ $t('add') }}</h4>
    </el-button>
    <el-button
      class="ml-2"
      type="primary"
      :disabled="!userCanWriteEngagementGainImportConfiguration || isSignedOff"
      @click="saveTableConfigurations"
    >
      <h4>{{ $t('save') }}</h4>
    </el-button>
  </el-row>
  <el-row
    v-for="(item, index) in gainTableConfigurations"
    :key="index"
    class="mt-1"
  >
    <table-configuration-card
      :configuration="item"
      :disable-delete="
        !userCanWriteEngagementGainImportConfiguration || isSignedOff
      "
      :file-configurations="gainFileConfigurations"
      @update="(configuration: TableConfiguration) => updateEntry(index, configuration)"
      @delete="deleteEntry(index)"
    />
  </el-row>
  <import-configuration-versions-modal
    v-model="showVersionModal"
    :configuration-versions="gainImportConfigurationHistory"
    :disable-restore="
      !userCanWriteEngagementGainImportConfiguration || isSignedOff
    "
    @restore="handleRestore"
    @close="showVersionModal = false"
  />
</template>

<script lang="ts">
import { ElRow, ElButton } from 'element-plus';
import { useStore } from 'vuex';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import {
  FileLogicType,
  GainImportConfiguration,
  InputFileConfiguration,
  RequirementsStatus,
  TableConfiguration
} from '@etp/etp-gain-bff-client/axios';
import TableConfigurationCard from './components/TableConfigurationCard.vue';
import ImportConfigurationVersionsModal from './components/ImportConfigurationVersionsModal.vue';

export default {
  name: 'GainImportTables',
  components: {
    ElRow,
    ElButton,
    ImportConfigurationVersionsModal,
    TableConfigurationCard
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const showVersionModal = ref(false);

    const gainImportConfiguration: ComputedRef<GainImportConfiguration> =
      computed(() => store.getters['import/getGainImportConfiguration']);
    const gainImportConfigurationHistory: ComputedRef<
      Array<GainImportConfiguration>
    > = computed(
      () => store.getters['import/getGainImportConfigurationHistory']
    );
    const gainTableConfigurations = ref(new Array<TableConfiguration>());
    const gainFileConfigurations = ref(new Array<InputFileConfiguration>());

    const addEntry = async () => {
      gainTableConfigurations.value.push({
        sourceFileRowsToSkip: 0,
        sourceFileColsToSkip: 0
      } as TableConfiguration);
    };
    const deleteEntry = async (index: number) => {
      gainTableConfigurations.value.splice(index, 1);
    };
    const updateEntry = async (
      index: number,
      configuration: TableConfiguration
    ) => {
      gainTableConfigurations.value[index] = configuration;
    };

    const openVersionModal = async () => {
      await store.dispatch('import/fetchGainImportConfigurationHistory');
      showVersionModal.value = true;
    };

    const handleRestore = async (configuration: GainImportConfiguration) => {
      await store.dispatch(
        'import/updateGainImportConfiguration',
        configuration
      );
      showVersionModal.value = false;
      await updateLocalVars();
    };

    const saveTableConfigurations = async () => {
      await store.dispatch('import/updateGainImportConfiguration', {
        ...gainImportConfiguration.value,
        tableConfigurations: gainTableConfigurations.value
      } as GainImportConfiguration);
      await updateLocalVars();
    };

    const updateLocalVars = async () => {
      await store.dispatch('import/fetchGainImportConfiguration');
      gainTableConfigurations.value = [
        ...(gainImportConfiguration.value.tableConfigurations ??
          new Array<TableConfiguration>())
      ];

      if (
        gainImportConfiguration.value.fileLogicType === FileLogicType.SqlLogic
      )
        return;

      gainFileConfigurations.value = [
        ...(gainImportConfiguration.value.fileConfigurations ??
          new Array<InputFileConfiguration>())
      ];
    };

    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    const isSignedOff: ComputedRef<boolean> = computed(
      () =>
        store.getters['gain/getCurrentRequirementsStatus']
          ?.requirementsStatus === RequirementsStatus.SignedOff
    );
    const userCanWriteEngagementGainImportConfiguration: ComputedRef<boolean> =
      computed(
        () =>
          store.getters[
            'userPermissions/canWriteEngagementGainImportConfiguration'
          ]
      );

    onMounted(async () => {
      if (!isGainEngagement.value) router.push({ name: 'ImportMonitor' });
      await store.dispatch('gain/fetchCurrentRequirementsStatus');
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementGainImportConfiguration'
      );
      await updateLocalVars();
    });

    return {
      addEntry,
      deleteEntry,
      gainFileConfigurations,
      gainImportConfiguration,
      gainImportConfigurationHistory,
      gainTableConfigurations,
      handleRestore,
      isSignedOff,
      openVersionModal,
      saveTableConfigurations,
      showVersionModal,
      updateEntry,
      userCanWriteEngagementGainImportConfiguration
    };
  }
};
</script>

<style scoped lang="scss"></style>
