import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "body-1" }
const _hoisted_2 = { class: "body-1" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_ai_services_configuration_form = _resolveComponent("ai-services-configuration-form")!
  const _component_container_registry_configuration_form = _resolveComponent("container-registry-configuration-form")!
  const _component_databricks_workspace_configuration_form = _resolveComponent("databricks-workspace-configuration-form")!
  const _component_data_factory_configuration_form = _resolveComponent("data-factory-configuration-form")!
  const _component_event_grid_topic_configuration_form = _resolveComponent("event-grid-topic-configuration-form")!
  const _component_function_app_configuration_form = _resolveComponent("function-app-configuration-form")!
  const _component_key_vault_configuration_form = _resolveComponent("key-vault-configuration-form")!
  const _component_machine_learning_workspace_configuration_form = _resolveComponent("machine-learning-workspace-configuration-form")!
  const _component_power_bi_workspace_configuration_form = _resolveComponent("power-bi-workspace-configuration-form")!
  const _component_sql_database_configuration_form = _resolveComponent("sql-database-configuration-form")!
  const _component_sql_server_configuration_form = _resolveComponent("sql-server-configuration-form")!
  const _component_storage_account_configuration_form = _resolveComponent("storage-account-configuration-form")!
  const _component_synapse_analytics_configuration_form = _resolveComponent("synapse-analytics-configuration-form")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_delete_resource_confirmation_modal = _resolveComponent("delete-resource-confirmation-modal")!
  const _component_edit_configuration_modal = _resolveComponent("edit-configuration-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_card, {
      "no-header": "",
      class: "width-100"
    }, {
      body: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString($props.resource.name), 1),
                _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.$t(Object.keys($setup.ResourceType)[$props.resource.resourceType])), 1),
                _createElementVNode("div", _hoisted_2, _toDisplayString($props.resource.tag), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                (
              $setup.includesResourceType(
                [$setup.ResourceType.AiServices],
                $props.resource.resourceType
              )
            )
                  ? (_openBlock(), _createBlock(_component_ai_services_configuration_form, {
                      key: 0,
                      configuration: $props.resource.configuration,
                      "managed-identity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ManagedIdentity)
                .map(el => el.name)
            ,
                      onUpdateConfiguration: $setup.handleUpdate
                    }, null, 8, ["configuration", "managed-identity-names", "onUpdateConfiguration"]))
                  : _createCommentVNode("", true),
                (
              $setup.includesResourceType(
                [$setup.ResourceType.ContainerRegistry],
                $props.resource.resourceType
              )
            )
                  ? (_openBlock(), _createBlock(_component_container_registry_configuration_form, {
                      key: 1,
                      configuration: $props.resource.configuration,
                      "managed-identity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ManagedIdentity)
                .map(el => el.name)
            ,
                      onUpdateConfiguration: $setup.handleUpdate
                    }, null, 8, ["configuration", "managed-identity-names", "onUpdateConfiguration"]))
                  : _createCommentVNode("", true),
                (
              $setup.includesResourceType(
                [$setup.ResourceType.DatabricksWorkspace],
                $props.resource.resourceType
              )
            )
                  ? (_openBlock(), _createBlock(_component_databricks_workspace_configuration_form, {
                      key: 2,
                      configuration: $props.resource.configuration,
                      onUpdateConfiguration: $setup.handleUpdate
                    }, null, 8, ["configuration", "onUpdateConfiguration"]))
                  : _createCommentVNode("", true),
                (
              $setup.includesResourceType(
                [$setup.ResourceType.DataFactory],
                $props.resource.resourceType
              )
            )
                  ? (_openBlock(), _createBlock(_component_data_factory_configuration_form, {
                      key: 3,
                      configuration: $props.resource.configuration,
                      "managed-identity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ManagedIdentity)
                .map(el => el.name)
            ,
                      "storage-accounts": 
              $props.resources.filter(
                el =>
                  el.resourceType === $setup.ResourceType.StorageAccount ||
                  el.resourceType === $setup.ResourceType.DataLakeStorage
              )
            ,
                      onUpdateConfiguration: $setup.handleUpdate
                    }, null, 8, ["configuration", "managed-identity-names", "storage-accounts", "onUpdateConfiguration"]))
                  : (
              $setup.includesResourceType(
                [$setup.ResourceType.EventGridTopic],
                $props.resource.resourceType
              )
            )
                    ? (_openBlock(), _createBlock(_component_event_grid_topic_configuration_form, {
                        key: 4,
                        configuration: $props.resource.configuration,
                        onUpdateConfiguration: $setup.handleUpdate
                      }, null, 8, ["configuration", "onUpdateConfiguration"]))
                    : (
              $setup.includesResourceType(
                [
                  $setup.ResourceType.FunctionAppDotnet,
                  $setup.ResourceType.FunctionAppPython
                ],
                $props.resource.resourceType
              )
            )
                      ? (_openBlock(), _createBlock(_component_function_app_configuration_form, {
                          key: 5,
                          configuration: $props.resource.configuration,
                          "managed-identity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ManagedIdentity)
                .map(el => el.name)
            ,
                          "storage-names": 
              $props.resources
                .filter(
                  el =>
                    el.resourceType === $setup.ResourceType.StorageAccount ||
                    el.resourceType === $setup.ResourceType.DataLakeStorage
                )
                .map(el => el.name)
            ,
                          onUpdateConfiguration: $setup.handleUpdate
                        }, null, 8, ["configuration", "managed-identity-names", "storage-names", "onUpdateConfiguration"]))
                      : (
              $setup.includesResourceType(
                [$setup.ResourceType.KeyVault],
                $props.resource.resourceType
              )
            )
                        ? (_openBlock(), _createBlock(_component_key_vault_configuration_form, {
                            key: 6,
                            configuration: $props.resource.configuration,
                            "managed-identity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ManagedIdentity)
                .map(el => el.name)
            ,
                            onUpdateConfiguration: $setup.handleUpdate
                          }, null, 8, ["configuration", "managed-identity-names", "onUpdateConfiguration"]))
                        : (
              $setup.includesResourceType(
                [$setup.ResourceType.MachineLearningWorkspace],
                $props.resource.resourceType
              )
            )
                          ? (_openBlock(), _createBlock(_component_machine_learning_workspace_configuration_form, {
                              key: 7,
                              configuration: $props.resource.configuration,
                              "managed-identity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ManagedIdentity)
                .map(el => el.name)
            ,
                              "storage-account-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.StorageAccount)
                .map(el => el.name)
            ,
                              "key-vault-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.KeyVault)
                .map(el => el.name)
            ,
                              "container-registry-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ContainerRegistry)
                .map(el => el.name)
            ,
                              "app-insights-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.AppInsights)
                .map(el => el.name)
            ,
                              onUpdateConfiguration: $setup.handleUpdate
                            }, null, 8, ["configuration", "managed-identity-names", "storage-account-names", "key-vault-names", "container-registry-names", "app-insights-names", "onUpdateConfiguration"]))
                          : (
              $setup.includesResourceType(
                [$setup.ResourceType.PowerBIWorkspace],
                $props.resource.resourceType
              )
            )
                            ? (_openBlock(), _createBlock(_component_power_bi_workspace_configuration_form, {
                                key: 8,
                                configuration: $props.resource.configuration,
                                "power-bi-capacity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.PowerBIEmbedded)
                .map(el => el.name)
            ,
                                onUpdateConfiguration: $setup.handleUpdate
                              }, null, 8, ["configuration", "power-bi-capacity-names", "onUpdateConfiguration"]))
                            : (
              $setup.includesResourceType(
                [$setup.ResourceType.SqlDatabase, $setup.ResourceType.SynapseDataWarehouse],
                $props.resource.resourceType
              )
            )
                              ? (_openBlock(), _createBlock(_component_sql_database_configuration_form, {
                                  key: 9,
                                  configuration: $props.resource.configuration,
                                  "sql-server-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.SqlServer)
                .map(el => el.name)
            ,
                                  onUpdateConfiguration: $setup.handleUpdate
                                }, null, 8, ["configuration", "sql-server-names", "onUpdateConfiguration"]))
                              : (
              $setup.includesResourceType(
                [$setup.ResourceType.SqlServer],
                $props.resource.resourceType
              )
            )
                                ? (_openBlock(), _createBlock(_component_sql_server_configuration_form, {
                                    key: 10,
                                    configuration: $props.resource.configuration,
                                    "managed-identity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ManagedIdentity)
                .map(el => el.name)
            ,
                                    onUpdateConfiguration: $setup.handleUpdate
                                  }, null, 8, ["configuration", "managed-identity-names", "onUpdateConfiguration"]))
                                : (
              $setup.includesResourceType(
                [$setup.ResourceType.DataLakeStorage, $setup.ResourceType.StorageAccount],
                $props.resource.resourceType
              )
            )
                                  ? (_openBlock(), _createBlock(_component_storage_account_configuration_form, {
                                      key: 11,
                                      configuration: $props.resource.configuration,
                                      onUpdateConfiguration: $setup.handleUpdate
                                    }, null, 8, ["configuration", "onUpdateConfiguration"]))
                                  : (
              $setup.includesResourceType(
                [$setup.ResourceType.SynapseAnalytics],
                $props.resource.resourceType
              )
            )
                                    ? (_openBlock(), _createBlock(_component_synapse_analytics_configuration_form, {
                                        key: 12,
                                        configuration: $props.resource.configuration,
                                        "data-lake-storage-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.DataLakeStorage)
                .map(el => el.name)
            ,
                                        "managed-identity-names": 
              $props.resources
                .filter(el => el.resourceType == $setup.ResourceType.ManagedIdentity)
                .map(el => el.name)
            ,
                                        onUpdateConfiguration: $setup.handleUpdate
                                      }, null, 8, ["configuration", "data-lake-storage-names", "managed-identity-names", "onUpdateConfiguration"]))
                                    : _createCommentVNode("", true)
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 6,
              class: "d-flex"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_tooltip, {
                  placement: "top",
                  content: $props.resource.configuration
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: require(`@/assets/images/icons/new/settings.svg`),
                      height: "12",
                      width: "12",
                      class: "ml-auto mt-1 action-icon",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showEditConfigurationModal = true))
                    }, null, 8, _hoisted_3)
                  ]),
                  _: 1
                }, 8, ["content"]),
                (!$props.deploymentInProgress)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      src: require(`@/assets/images/icons/new/delete.svg`),
                      height: "12",
                      width: "12",
                      class: "action-icon ml-1 mt-1 mr-2",
                      onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showDeleteResourceModal = true))
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_delete_resource_confirmation_modal, {
      modelValue: $setup.showDeleteResourceModal,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.showDeleteResourceModal) = $event)),
      "type-text": Object.keys($setup.ResourceType)[$props.resource.resourceType],
      "name-text": $props.resource.name,
      onConfirm: $setup.handleDelete
    }, null, 8, ["modelValue", "type-text", "name-text", "onConfirm"]),
    _createVNode(_component_edit_configuration_modal, {
      modelValue: $setup.showEditConfigurationModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.showEditConfigurationModal) = $event)),
      configuration: $props.resource.configuration,
      onConfirm: $setup.handleUpdate,
      onClose: _cache[4] || (_cache[4] = ($event: any) => ($setup.showEditConfigurationModal = false))
    }, null, 8, ["modelValue", "configuration", "onConfirm"])
  ], 64))
}