<template>
  <el-form :model="form">
    <el-form-item prop="deployMachineLearningPrivateEndpoint" class="mb-0">
      <el-checkbox
        class="checkbox"
        :label="$t('deployMachineLearningPrivateEndpoint')"
        :model-value="form.deployMachineLearningPrivateEndpoint"
        @change="handleChangeDeployMachineLearningPrivateEndpoint"
      />
    </el-form-item>
    <el-form-item :label="$t('managedIdentityName')" class="mb-0">
      <el-select
        v-model="form.managedIdentityName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeManagedIdentityName"
      >
        <el-option
          v-for="item in managedIdentityNames"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('storageAccountName')" class="mb-0">
      <el-select
        v-model="form.storageAccountName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeStorageAccountName"
      >
        <el-option
          v-for="item in storageAccountNames"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('keyVaultName')" class="mb-0">
      <el-select
        v-model="form.keyVaultName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeKeyVaultName"
      >
        <el-option
          v-for="item in keyVaultNames"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('containerRegistryName')" class="mb-0">
      <el-select
        v-model="form.containerRegistryName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeContainerRegistryName"
      >
        <el-option
          v-for="item in containerRegistryNames"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
    <el-form-item :label="$t('appInsightsName')">
      <el-select
        v-model="form.appInsightsName"
        size="small"
        :placeholder="$t('empty')"
        @change="handleChangeAppInsightsName"
      >
        <el-option
          v-for="item in appInsightsNames"
          :key="item"
          :label="item"
          :value="item"
        />
      </el-select>
    </el-form-item>
  </el-form>
</template>
<script lang="ts">
import {
  CheckboxValueType,
  ElCheckbox,
  ElForm,
  ElFormItem,
  ElOption,
  ElSelect
} from 'element-plus';
import { MachineLearningWorkspaceConfiguration } from '@/views/admin/tenants/models/TenantAzureConfigurationObjects';
import { onMounted, onUpdated, ref } from 'vue';

export default {
  name: 'MachineLearningWorkspaceConfigurationCard',
  components: { ElCheckbox, ElForm, ElFormItem, ElOption, ElSelect },
  props: {
    configuration: { type: String, default: '' },
    managedIdentityNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    },
    storageAccountNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    },
    keyVaultNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    },
    containerRegistryNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    },
    appInsightsNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    }
  },
  emits: ['update-configuration'],
  setup(props, { emit }) {
    let form = ref(new MachineLearningWorkspaceConfiguration());
    let localConfigurationObject = ref({});

    const emitUpdateEvent = () => {
      emit(
        'update-configuration',
        JSON.stringify({
          ...localConfigurationObject.value,
          ...form.value
        })
      );
    };

    const handleChangeDeployMachineLearningPrivateEndpoint = (
      newValue: CheckboxValueType
    ) => {
      form.value.deployMachineLearningPrivateEndpoint = !!newValue;
      emitUpdateEvent();
    };

    const handleChangeManagedIdentityName = (newValue: string) => {
      form.value.managedIdentityName = newValue;
      emitUpdateEvent();
    };

    const handleChangeStorageAccountName = (newValue: string) => {
      form.value.storageAccountName = newValue;
      emitUpdateEvent();
    };

    const handleChangeKeyVaultName = (newValue: string) => {
      form.value.keyVaultName = newValue;
      emitUpdateEvent();
    };

    const handleChangeContainerRegistryName = (newValue: string) => {
      form.value.containerRegistryName = newValue;
      emitUpdateEvent();
    };

    const handleChangeAppInsightsName = (newValue: string) => {
      form.value.appInsightsName = newValue;
      emitUpdateEvent();
    };

    const updateLocalVars = () => {
      localConfigurationObject.value = JSON.parse(props.configuration || '{}');
      form.value.deployMachineLearningPrivateEndpoint =
        localConfigurationObject.value[
          'deployMachineLearningPrivateEndpoint'
        ] ?? false;
      form.value.managedIdentityName =
        localConfigurationObject.value['managedIdentityName'] ?? '';
      form.value.storageAccountName =
        localConfigurationObject.value['storageAccountName'] ?? '';
      form.value.keyVaultName =
        localConfigurationObject.value['keyVaultName'] ?? '';
      form.value.containerRegistryName =
        localConfigurationObject.value['containerRegistryName'] ?? '';
      form.value.appInsightsName =
        localConfigurationObject.value['appInsightsName'] ?? '';
    };

    onMounted(() => {
      updateLocalVars();
      emitUpdateEvent();
    });
    onUpdated(() => updateLocalVars());

    return {
      form,
      handleChangeAppInsightsName,
      handleChangeContainerRegistryName,
      handleChangeDeployMachineLearningPrivateEndpoint,
      handleChangeKeyVaultName,
      handleChangeManagedIdentityName,
      handleChangeStorageAccountName
    };
  }
};
</script>
