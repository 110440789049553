import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "body-1"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 0,
  class: "body-1 d-block"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "body-1 d-block" }
const _hoisted_6 = {
  key: 0,
  class: "ml-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    "no-header": "",
    class: "width-100"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 8 }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString($props.resource.name), 1),
              ($props.resource)
                ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.$t(Object.keys($setup.ResourceType)[$props.resource.resourceType])), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 12 }, {
            default: _withCtx(() => [
              (
              $setup.includesResourceType(
                [
                  $setup.ResourceType.AiServices,
                  $setup.ResourceType.ContainerRegistry,
                  $setup.ResourceType.DataFactory,
                  $setup.ResourceType.DataLakeStorage,
                  $setup.ResourceType.EventGridTopic,
                  $setup.ResourceType.KeyVault,
                  $setup.ResourceType.MachineLearningWorkspace,
                  $setup.ResourceType.SqlServer,
                  $setup.ResourceType.StorageAccount,
                  $setup.ResourceType.SynapseAnalytics
                ],
                $props.resource.resourceType
              )
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('privateEndpoints')), 1),
                    (!$setup.privateEndpointInfo.length)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.$t('none')), 1))
                      : _createCommentVNode("", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.privateEndpointInfo, (privateEndpoint) => {
                      return (_openBlock(), _createElementBlock("span", {
                        key: privateEndpoint.ip,
                        class: "body-1 d-block"
                      }, _toDisplayString(privateEndpoint.ip + ' ' + privateEndpoint.fqdn), 1))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              (
              $setup.includesResourceType(
                [$setup.ResourceType.SqlDatabase, $setup.ResourceType.SynapseDataWarehouse],
                $props.resource.resourceType
              )
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('sqlServerName')), 1),
                    _createElementVNode("span", _hoisted_5, _toDisplayString($setup.sqlServerInfo || _ctx.$t('none')), 1)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 4,
            class: "d-flex"
          }, {
            default: _withCtx(() => [
              (
              $setup.includesResourceType(
                [
                  $setup.ResourceType.AnalysisServices,
                  $setup.ResourceType.DataFactory,
                  $setup.ResourceType.GitRepository,
                  $setup.ResourceType.PowerBIWorkspace
                ],
                $props.resource.resourceType
              )
            )
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: $setup.redirect
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('open')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}